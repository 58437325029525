import { useEffect } from 'react';
import '../../assets/css/chatbot.css';
import Authenticate from './Authenticate';
import ChatbotHeader from './Header';
import MainScreen from './MainScreen';
import { useState } from 'react';

const AIChatBot = () => {
    const [session, setSession] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        const hasSession = window.sessionStorage.getItem('session');
        setSession(!!hasSession);
    }, [])

    return (
        <div className="container-lg">
            <div className="cb-container p-2">
                <ChatbotHeader />
                <div className="authenticate p-4" style={{ height: session && !isLoading ? 'auto' : '82vh' }}>
                    {session ? <MainScreen setIsLoading={setIsLoading} setSession={setSession} /> : <Authenticate loginSuccess={() => setSession(true)} />}
                </div>
            </div>
        </div>
    )
}

export default AIChatBot;