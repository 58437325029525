import Logo from '../../assets/img/logo.png'

const ChatbotHeader = () => {

    return (
        <div className="header mb-3 p-3 align-items-center d-flex justify-content-center">
            <img src={Logo} height={50} alt="Logo" />
            <p style={{ color: "aliceblue", fontSize: "25px", whiteSpace: "pre" }} className="mt-2">  Arogya Pandit</p>
        </div>
    )
}

export default ChatbotHeader;