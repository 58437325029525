const AvatarVideo = ({ avatar, fullscreen, setFullscreen, handleVideoLoad,setSession }) => {
    const { myAvatarVideoEleRef: videoRef, myAvatarAudioEleRef: audioRef, stopSession, speakText } = avatar;

    const logout = () => {
        stopSession()
        setSession(false)
    }

    return (
        <div style={{ position: 'relative' }}>
            <div className="video-header mb-2 py-2 px-3 d-flex justify-content-between align-items-center">
                <div className="left d-flex justify-content-start align-items-center" style={{ gap: '10px' }}>
                    {!fullscreen && <div>
                        <button onClick={() => setFullscreen(true)} className="back-button btn btn-sm"><i className="fa fa-arrow-left"></i></button>
                    </div>}
                    {fullscreen && <button onClick={() => setFullscreen(false)} className="back-button btn btn-sm"><i className="fa-solid fa-comments"></i></button>}
                    <div>
                        <div className="vhtitle">ArogyaAI</div>
                        <div className="sub">AI chat with <b>Gourav</b></div>
                    </div>
                </div>
                <div className="right">
                    <button onClick={logout} className="btn btn-sm btn-danger"><i className="fa-solid fa-right-from-bracket"></i></button>
                </div>
            </div>

            <div className={fullscreen ? "video-container" : "video-container"} >
                <video ref={videoRef} onCanPlayThrough={handleVideoLoad}>
                    Your browser does not support the video tag.
                </video>
                <audio ref={audioRef}>
                    Your browser does not support the audio tag.
                </audio>
            </div>

            <div className="video-controls">
                <div onClick={() => avatar.stopSpeaking()} className="d-flex align-items-center justify-content-between control-btns">
                    <div className="control-btn d-flex justify-content-center align-items-center mx-2">
                        <i  style={{color: avatar.speaking ?  'red' : 'black'}} className={avatar.speaking ? "fa-solid fa-volume-high" : "fa-solid fa-volume-off" }></i>
                    </div>
                    <div onClick={speakText} className="control-btn unmuted d-flex justify-content-center align-items-center mx-2 my-2">
                        <i style={{ color: avatar.listening ?  'red' : 'black', fontSize: avatar.listening ?  '25px' : '18px' }} 
                        className={avatar.listening ?  "fa-solid fa-microphone" : "fa-solid fa-microphone-slash"}></i>
                    </div>
                    {/* <div className="control-btn d-flex justify-content-center align-items-center mx-2 my-2">
                        <i className="fa-solid fa-user"></i> 
                    </div>*/}
                    <div className="control-btn d-flex justify-content-center align-items-center mx-2 my-2">
                        <i className="fa-solid fa-ellipsis"></i>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AvatarVideo;
