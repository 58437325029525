import React, { useEffect, useRef, useState } from 'react';
import '../../assets/css/chatroom.css';
import SpeakingAnimation from './Speaking';
import UserIcon from '../../assets/img/user.png'
import moment from 'moment'
import BoyIcon from '../../assets/img/boy.png'
import LisaIcon from '../../assets/img/lisa-avatar.png'


const Chatroom = ({ avatar }) => {
    const endOfMessagesRef = useRef(null);
    const [message,setMessage] = useState('')

    useEffect(() => {
        endOfMessagesRef.current.scrollIntoView({ behavior: 'smooth' });

    }, [avatar.messageResponses]); 
    

    const sendMessage = () => {
        if(!message)  return ;
        avatar.sendMessages({sender : 'Gourav' , text : message})
        setMessage('')
    }

    return (
        <div className="chatroom p-4">
            <div className="messages-container mb-2">
                {avatar.messageResponses.map((message, index) => (
                    <div className="d-flex justify-content-start align-items-center bubble">
                        <img src={message.sender == 'ArogyaAI' ? LisaIcon :BoyIcon } width={40} />
                        <div style={{ width: '100%' }}>
                            <div className="d-flex justify-content-between align-items-center mb-2">
                                <div className="sender">{message.sender}</div>
                                <span className='time'>{moment(message.timestamp).format('dddd h:mma')}</span>
                            </div>
                            <div className={`sender-message${message.sender == 'ArogyaAI' ? '' : ' default'}`}> {message.text}</div>
                        </div>
                    </div>
                ))}
                <div ref={endOfMessagesRef}></div>
            </div>
            <div className="input-area d-flex justify-content-between align-items-center px-3" >
                {!avatar.listening ? <div style={{ display: 'inline-flex', alignItems: 'center' ,width: "100%" }}>
                    <i onClick={() => avatar.speakText() } style={{ cursor: 'pointer' }} className="fa-solid fa-microphone-lines"></i>
                    <input type="text" className='mx-2' value={message} onChange={(e) => setMessage(e.target.value)} placeholder="Send Your Message ..." />
                </div> : <SpeakingAnimation />}
                <i onClick={sendMessage} className="fa-solid fa-paper-plane" style={{ fontSize: '20px', color: 'rgb(194, 214, 103)', cursor: 'pointer' }}></i>
            </div>
        </div>
    );
};

export default Chatroom;
