// import logo from './logo.svg';
// import './App.css';
// import { Avatar } from './components/Avatar';
import AIChatBot from './components/chatbot/Chatbot';

function App() {
    return (
        <div className="App">
            {/* <Avatar/> */}
            <AIChatBot></AIChatBot>
        </div>
    );
}

export default App;
