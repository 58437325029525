
export const avatarAppConfig = {
  cogSvcRegion : "westus2",
  cogSvcSubKey : "27d07cf727944d07ada0f99b13fff1b8",
  voiceName : "en-US-JennyNeural",
  avatarCharacter : "lisa",
  avatarStyle : "casual-sitting",
  avatarBackgroundColor : "#808000",
  iceUrl : "stun:relay.communication.microsoft.com:3478",
  iceUsername : "BQAANk0zXQAB2nvAFw03glskJKrpTJrWNcyRlEduBU0AAAAMARBSErjxRFxBJYW7IIwyLINm7Ie7s3zTQDX7FtTVsQJeHD4RfZw=",
  iceCredential : "jcxzk6Ij+FA0Mb8YI01e4j+Aa74="
}