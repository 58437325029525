import React ,{ useRef, useState } from "react";

const Authenticate = ({ loginSuccess }) => {  

   const userName = useRef()
   const password = useRef()
   const [showValidation,setShowValidation] = useState(false)

   const checkValidation = () => {
    if(!userName.current.value || !password.current.value) return setShowValidation(true)
    if(userName.current.value !== 'Gourav') return setShowValidation(true)
    if(password.current.value !==  'arogyapandit' )return  setShowValidation(true)  
    window.sessionStorage.setItem('session','Arogyapandit')
   
    loginSuccess()
   }

    return (
        <div className="avatar-bg d-flex align-items-center justify-content-center">
            <div className="login-box">
                <div className="icon my-2"><i className="fa fa-user"></i></div>
                <div className="form-group mb-3">
                    <label htmlFor="userId" className="mb-2">Enter your User ID</label><br />
                    <input placeholder="user@example.com" ref={userName} type="text" id="userId" className="user-id" />
                </div>
                <div className="form-group mb-3">
                    <label htmlFor="userId" className="mb-2">Enter your Password</label><br />
                    <input type="password" id="password" ref={password} className="user-id" />
                </div>
                
                {showValidation &&  <div  className="mb-2" style={{color:'red'}}>Invalid user name / password</div>}
                <div className="d-flex" style={{ gap: '12px' }}>
                    <button className="cb-continue green" onClick={checkValidation}>Continue</button>
                    <button className="cb-continue light" style={{ color: "#fff" }}>Ask Something Else</button>
                </div>
            </div>
        </div>
    )
}

export default Authenticate;