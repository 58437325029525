import React, { useEffect, useState } from 'react';
import AvatarVideo from './AvatarVideo';
import { useAvatarHook } from '../../hooks/useTextToSpeech';
import '../../assets/css/loader.css'
import Chatroom from './Chatroom';

const MainScreen = ({ setIsLoading,setSession }) => {
    const [showLoader, setShowLoader] = useState(true);
    const [fullscreen, setFullscreen] = useState(true);
    const text = `Hello and welcome to ArogyaPandit! I'm Lisa, your intelligent digital assistant. At ArogyaPandit, we're passionate about harnessing the power of artificial intelligence to solve real-world problems. Our mission is to deliver cutting-edge AI solutions that drive innovation, efficiency, and growth for businesses and individuals alike. How may I assist you today?`
    const avatar = useAvatarHook();

    const handleVideoLoad = () => {
        setShowLoader(false);
        setIsLoading(false);
        setTimeout(() => {
            avatar.messageResponses.push({sender:'ArogyaAI',text:text,timestamp:new Date().getTime()})
            avatar.speakSelectedText(text)
           
        },1500)
    
    };

    useEffect(() => {
        avatar.startSession();
        // return () => {avatar.stopSession()}
    }, []);
    return (
        <>
            {showLoader && <div className='d-flex justify-content-center align-items-center h-100'>
                <div className="lds-facebook"><div></div><div></div><div></div></div>
            </div>}
            <div className="row" style={{ display: showLoader ? 'none' : 'flex' }}>
                {!fullscreen && <div className="col-md-5">
                    <Chatroom avatar={avatar} />
                </div>}
                <div className={fullscreen ? 'col-md-12' : 'col-md-7'}>
                    <AvatarVideo avatar={avatar} handleVideoLoad={handleVideoLoad} setSession={setSession} fullscreen={fullscreen} setFullscreen={setFullscreen} />
                </div>
            </div>
        </>
    );
};

export default MainScreen;
