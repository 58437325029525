import React from 'react';
import '../../assets/css/speaking.css'; // Import the CSS from a separate file

const SpeakingAnimation = () => {
    return (
        <div className='d-flex align-items-center' style={{ gap: '8px' }}>
            <i className="fa fa-microphone-lines" style={{ color: 'red' }}></i>
            Listening <div className="playing">
                <span className="playing__bar playing__bar1"></span>
                <span className="playing__bar playing__bar2"></span>
                <span className="playing__bar playing__bar3"></span>
            </div>
        </div>
    );
};

export default SpeakingAnimation;
