import { useState, useRef, useCallback } from 'react';
import * as SpeechSDK from "microsoft-cognitiveservices-speech-sdk";
import { createAvatarSynthesizer, createWebRTCConnection } from "../components/Utility";
import { avatarAppConfig } from "../components/config";
import axios from "axios";

export const useAvatarHook = () => {
    const [avatarSynthesizer, setAvatarSynthesizer] = useState(null);
    const myAvatarVideoEleRef = useRef(null);
    const myAvatarAudioEleRef = useRef(null);
    const [showLoader, setShowLoader] = useState(false);
    const [defaultImage, setDefaultImage] = useState(true);
    const [messageResponses,setMessageResponses] = useState([])
    const [listening,setListening] = useState(false)
    const [speaking,setSpeaking] = useState(false)

    const iceUrl = avatarAppConfig.iceUrl;
    const iceUsername = avatarAppConfig.iceUsername;
    const iceCredential = avatarAppConfig.iceCredential;

    const handleOnTrack = (event) => {
        console.log("#### Printing handle onTrack ", event);
        if (event.track.kind === 'video') {
            const mediaPlayer = myAvatarVideoEleRef.current;
            mediaPlayer.srcObject = event.streams[0];
            mediaPlayer.autoplay = true;
            mediaPlayer.playsInline = true;

            mediaPlayer.addEventListener('play', () => {
                setShowLoader(false);
                setDefaultImage(false);
            });
        } else {
            const audioPlayer = myAvatarAudioEleRef.current;
            audioPlayer.srcObject = event.streams[0];
            audioPlayer.autoplay = true;
            audioPlayer.playsInline = true;
            audioPlayer.muted = true;
        }
    }
    const getResponseFromChatgpt = async (message) => {
        try {
            let response = await axios.post("/api/fetchDataToChatGpt",  { message: message } );
            pushMessage({sender:'ArogyaAI',text:response.data.message,timestamp: new Date().getTime()})
            await speakSelectedText(response.data.message)
        } catch (error) {
            console.log(error);
        }
    }
    function pushMessage(data) {
        setMessageResponses((prevData) => [...prevData,data])
    }
    const speakSelectedText = async (message) => {
        setSpeaking(true)
        const audioPlayer = myAvatarAudioEleRef.current;
        audioPlayer.muted = false; 
        avatarSynthesizer.speakTextAsync(message, result => {
            if (result.reason === SpeechSDK.ResultReason.SynthesizingAudioCompleted) {
                console.log("Speech and avatar synthesized to video stream.");
               
            } else {
                console.error("Unable to speak. Result ID: ", result.resultId);
                if (result.reason === SpeechSDK.ResultReason.Canceled) {
                    let cancellationDetails = SpeechSDK.CancellationDetails.fromResult(result);
                    console.error(cancellationDetails.reason);
                    if (cancellationDetails.reason === SpeechSDK.CancellationReason.Error) {
                        console.error(cancellationDetails.errorDetails);
                    }
                }
            }
            setSpeaking(false)
        }, error => {
            console.error(error);
            avatarSynthesizer.close();
        }).catch(error => console.error(error));
    }
    const startSession = () => {
        setShowLoader(true);
        let peerConnection = createWebRTCConnection(iceUrl, iceUsername, iceCredential);

        peerConnection.ontrack = handleOnTrack;
        peerConnection.addTransceiver('video', { direction: 'sendrecv' });
        peerConnection.addTransceiver('audio', { direction: 'sendrecv' });
        let synthesizer = createAvatarSynthesizer();

        setAvatarSynthesizer(synthesizer);

        peerConnection.oniceconnectionstatechange = e => {
            console.log("WebRTC status: ", peerConnection.iceConnectionState);
            if (peerConnection.iceConnectionState === 'connected') {
                console.log("Connected to Azure Avatar service");
            } else if (peerConnection.iceConnectionState === 'disconnected' || peerConnection.iceConnectionState === 'failed') {
                console.log("Azure Avatar service Disconnected");
            }
        };
        console.log(peerConnection);
        synthesizer.startAvatarAsync(peerConnection).then(() => {
            console.log("[" + (new Date()).toISOString() + "] Avatar started.");
        }).catch((error) => {
            console.error("[" + (new Date()).toISOString() + "] Avatar failed to start. Error: ", error);
            setShowLoader(false);
        });
    }
    const stopSession = () => {
        try {
            avatarSynthesizer.stopSpeakingAsync().then(() => {
                console.log("Stop speaking request sent.");
                avatarSynthesizer.close();
            }).catch(e => console.error(e));
        } catch (e) {
            console.error(e);
        }
    }
    const debounce = (func, wait = 1000) => {
        let timeout;
      
        return (...args) => {
          clearTimeout(timeout);
          timeout = setTimeout(() => func(...args), wait);
        };
      };

    const speakText = async () => {
        try {
            setListening(true)
            const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
            const speechConfig = SpeechSDK.SpeechConfig.fromSubscription(avatarAppConfig.cogSvcSubKey, avatarAppConfig.cogSvcRegion);
            speechConfig.speechRecognitionLanguage = "en-US";

            let audioConfig = SpeechSDK.AudioConfig.fromDefaultMicrophoneInput();
            let speechRecognizer = new SpeechSDK.SpeechRecognizer(speechConfig, audioConfig);

            speechRecognizer.recognizeOnceAsync(async (result) => {
                if (result.reason === SpeechSDK.ResultReason.RecognizedSpeech) {
                    console.log("MATCHED : Speech recognized") 
                    // setListening(false)
                    debounce(setListening,1000)(false) 

                    // messageResponses.push({sender:'Gourav',text:result.text,timestamp: new Date().getTime()})
                    pushMessage({sender:'Gourav',text:result.text,timestamp: new Date().getTime()})
                    await getResponseFromChatgpt(result.text)
                } else if (result.reason === SpeechSDK.ResultReason.NoMatch) {
                    console.log("NOMATCH: Speech could not be recognized.");
                } else if (result.reason === SpeechSDK.ResultReason.Canceled) {
                    const cancellation = SpeechSDK.CancellationDetails.fromResult(result);
                    console.error(`CANCELED: Reason=${cancellation.reason}`);
                }
                speechRecognizer.close();
            });
        } catch (error) {
            console.error(error);
        }
    }
    const sendMessages = async (data) => { 
        // messageResponses.push({sender:data.sender,text:data.text,timestamp: new Date().getTime()})
        pushMessage({sender:data.sender,text:data.text,timestamp: new Date().getTime()})
        await getResponseFromChatgpt(data.text)
        // console.log(messageResponses)
    }
    const stopSpeaking = () => {
        avatarSynthesizer.stopSpeakingAsync().then(() => {
            setSpeaking(false)
            console.log("[" + (new Date()).toISOString() + "] Stop speaking request sent.")

        }).catch();
    }
    return { startSession, speakText, stopSession, sendMessages,
        speakSelectedText, myAvatarVideoEleRef, myAvatarAudioEleRef,speaking,
        showLoader, defaultImage,messageResponses,listening,stopSpeaking };
};
